export default class InvoiceApiClient {
  // Accepts the invoice data file and attaches it to a form data with file param
  submitInvoice = async (invoiceFile, userEmail) => {
    const formData = new FormData();
    formData.append("file", invoiceFile);
    formData.append("email", userEmail);
    let response = {};
    const url = "/invoices/api/submitInvoice";
    await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    })
      .then(async response => {
        if (response.ok) return response;
        const errorText = await response.text()
        throw new Error(errorText);
      })
      .then(data => {
        response = data;
      });
    return response;
  };
}
